import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import { KeyTableColumn } from "../table/TableDefinitions";
import * as ExcelJS from "exceljs";

export const exportDataAsCSV = async (
  columns: TableManagerColumn[],
  tableData: TableEntry[],
  filename: string
): Promise<void> => {
  const dataArr: Array<string> = [];
  const exportColumns = columns.filter((col) => isExportCol(col));

  dataArr.push(exportColumns.map((col) => `"${col.title}"`).join(";")); // column headers
  const data = getVisibleFilteredTableData(columns, tableData);
  data.forEach((row) => {
    dataArr.push(row.map((cellData) => `"${cellData}"`).join(";")); // row data
  });

  const dataToExport = "\uFEFF" + dataArr.join("\n");
  await downloadData(new Blob([dataToExport], { type: "text/csv;charset=UTF-8" }), filename);
};

export const exportDataAsXLSX = async (
  columns: KeyTableColumn[],
  tableData: TableEntry[],
  filename: string,
  worksheetName: string
): Promise<void> => {
  const exportData = getVisibleFilteredTableData(columns, tableData);
  const exportColumns = columns.filter((col) => isExportCol(col));
  const cellSizeArr = sampleCellWidth(
    exportColumns.map((col) => col.title),
    exportData
  );

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);
  worksheet.columns = exportColumns.map((col, index) => {
    return { header: col.title, key: col.nameKey, width: cellSizeArr[index] };
  });
  worksheet.getRow(1).font = { bold: true, size: 12 };
  worksheet.autoFilter = {
    from: "A1",
    to: `${String.fromCharCode("A".charCodeAt(0) + exportColumns.length - 1)}1`,
  };
  exportData.forEach((row) => worksheet.addRow(row));

  const buffer = await workbook.xlsx.writeBuffer();
  await downloadData(
    new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    }),
    filename
  );
};

const downloadData = async (blob: Blob, filename: string): Promise<void> => {
  try {
    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    setTimeout(() => {
      URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    }, 100);
  } catch (error: unknown) {
    throw new Error("Error occurred when downloading file: " + filename);
  }
};

/**
 * Traverse columns and data to find a suitable cell width
 * @param columnTitles column titles
 * @param exportData export data
 */
const sampleCellWidth = (columnTitles: string[], exportData: Array<string[]>): number[] => {
  const DATA_MAX_WIDTH = 50;
  const cellSizeArr: number[] = [];
  columnTitles.forEach((colTitle) => cellSizeArr.push(colTitle.length));

  exportData.forEach((row) => {
    row.forEach((col, index) => {
      if (cellSizeArr[index] < col.length) {
        cellSizeArr[index] = Math.min(col.length, DATA_MAX_WIDTH);
      }
    });
  });

  const CELL_SPACING = 4; // add some spacing
  return cellSizeArr.map((cellSize) => cellSize + CELL_SPACING);
};

const getVisibleFilteredTableData = (
  allColumns: TableManagerColumn[],
  tableData: TableEntry[]
): Array<string[]> => {
  const extractedData: Array<string[]> = [];
  tableData.forEach((rowData) => {
    const row: string[] = [];
    allColumns.forEach((col, index) => {
      if (isExportCol(col)) {
        if (typeof rowData[index] === "object" && (<any>rowData[index]).content) {
          row.push((<any>rowData[index]).content);
        } else {
          row.push("" + rowData[index]);
        }
      }
    });
    extractedData.push(row);
  });
  return extractedData;
};

/**
 * Return true if this is a col that will be exported.
 * Visible column and a column that makes sense to export (has some data).
 * @param col
 */
const isExportCol = (col: TableManagerColumn):boolean => {
  return col.isChecked && col.type !== "button";
}
