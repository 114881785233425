import React from "react";
import styled from "styled-components";
import { spacing } from "@teliads/components/foundations";
import { useMessage } from "@messageformat/react";
import { TeliaNotification } from "@teliads/components/react";

const ExportErrorContainer = styled.div`
  margin-top: ${spacing.spacing16};
  margin-bottom: ${spacing.spacing24};
  width: fit-content;
`;

export function ExportError() {
  const text = useMessage("export-error");
  return (
    <ExportErrorContainer>
      <TeliaNotification status={"error"} headingTag={"h3"}>
        <span slot={"heading"}>{text}</span>
      </TeliaNotification>
    </ExportErrorContainer>
  );
}
