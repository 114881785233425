import {
  AccessProfileInfoDTO,
  AccessProfileInfoListDTO,
  CustomerGroupStructureDTO,
} from "@telia/b2b-rest-client/src/corp-sra-mybusiness-facade";
import { getByKey } from "@telia/b2b-roles-lib";
import {
  CategoryId,
  getCategory,
  getTranslatedSubCategoryDisplayName,
} from "@telia/b2b-product-categories-lib";
import { currentLanguage } from "@telia/b2b-i18n";
import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { DataTranslations } from "../hooks/useDataTranslations";

export function getCompleteUrl(relativeUrl: string): string {
  const basePath = window.location.pathname;
  return basePath.endsWith("/") ? relativeUrl : basePath + "/" + relativeUrl;
}

export interface UserData {
  legacyId: string;
  accessProfileId: number;
  federated: boolean;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  mobileNumber: string;

  // Lists that get flattened to fit in a column ...
  roles: string;
  organisations: string;
  units: string;
  costLocations: string;
  productCategories: string;
  agreements: string;
  vpns: string;

  lastActivity: string;
  invitedBy: string;
  invitedDate: string;

  name: { content: string; href: string };
  telephoneNumber: string;
}

export function mapUserDataList(
  dataTranslations: DataTranslations,
  profilesInfo?: AccessProfileInfoListDTO
): TableEntry[] {
  if (!profilesInfo) {
    return [];
  }

  const mappedProfiles =
    mapUserProfiles(profilesInfo.accessProfileInfoDTOS, dataTranslations) ?? [];

  return mappedProfiles.map((userItem) => {
    const dataRow = [
      userItem.accessProfileId,
      userItem.name,
      userItem.userName,
      userItem.email,
      userItem.telephoneNumber,
      userItem.roles,
      userItem.organisations,
    ];

    if (profilesInfo.enableUnits) {
      dataRow.push(userItem.units);
    }
    if (profilesInfo.enableCostCenters) {
      dataRow.push(userItem.costLocations);
    }

    dataRow.push(userItem.productCategories);

    if (profilesInfo.enableAgreements) {
      dataRow.push(userItem.agreements);
    }
    if (profilesInfo.enableVpns) {
      dataRow.push(userItem.vpns);
    }
    if (profilesInfo.enableFederatedUsers) {
      if (userItem.federated) {
        dataRow.push("SSO");
      } else {
        dataRow.push("MyBusiness");
      }
    }

    dataRow.push(userItem.lastActivity, userItem.invitedBy, userItem.invitedDate);

    return dataRow;
  });
}

export function handleInviteStatus(status: InviteStatus) {
  const localeKey = InvitesStatusDict[status];
  return localeKey ? localeKey[currentLanguage() as "en" | "sv"] : status;
}

export function handleCustomerGroup(
  customerGroup: CustomerGroupStructureDTO,
  dataTranslations: DataTranslations
): string {
  if (isMissingStructureAccess(customerGroup)) {
    return "MISSING_ACCESS";
  }

  const customerGroupName = customerGroup.name ? customerGroup.name : ""; // Not likely to be undefined
  if (isRestrictedOnCustomerGroup(customerGroup)) {
    return customerGroupName;
  }

  if (isRestrictedOnOrganization(customerGroup)) {
    return customerGroup?.orgs
      ? customerGroup.orgs.map((org: any) => dataTranslations.t(org.name)).join(", ")
      : customerGroupName;
  }

  if (isRestrictedOnUnit(customerGroup)) {
    return customerGroup.orgs
      .flatMap((org) => (org.units ? org.units.map((unit) => unit.name) : org.name))
      .map((cg) => dataTranslations.t(cg))
      .join(", ");
  }

  if (isRestrictedOnCostCenter(customerGroup)) {
    return customerGroup.orgs
      .flatMap((org) =>
        org.units
          ? org.units.flatMap((unit) =>
              unit.costCenters ? unit.costCenters.map((cc) => cc.name) : [unit.name]
            )
          : [org.name]
      )
      .map((cg) => dataTranslations.t(cg))
      .join(", ");
  }

  return "Unknown_Access";
}

export function mapUserProfiles(
  userProfiles: Array<AccessProfileInfoDTO> | undefined,
  dataTranslations: DataTranslations
): UserData[] | undefined {
  const translateProductCategories = (listData: Array<string>): Array<string> => {
    // Return translation of known product categories, otherwise return data as is (i.e. missing translation)
    return listData.map((data) =>
      getCategory(data as CategoryId)
        ? getTranslatedSubCategoryDisplayName(data as CategoryId)
        : data
    );
  };

  return userProfiles?.map((user) => ({
    // Pull in latest rest-client refresh ...
    ...user,

    roles: user.roles ? handleRoles(dataTranslations.tList(user.roles)) : "",
    organisations: user.organisations ? dataTranslations.tList(user.organisations).join(", ") : "",
    units: user.units ? dataTranslations.tList(user.units).join(", ") : "",
    costLocations: user.costLocations ? dataTranslations.tList(user.costLocations).join(", ") : "",
    productCategories: user.productCategories
      ? translateProductCategories(dataTranslations.tList(user.productCategories)).join(", ")
      : "",
    agreements: user.agreements ? dataTranslations.tList(user.agreements).join(", ") : "",
    vpns: user.vpns ? dataTranslations.tList(user.vpns).join(", ") : "",

    name: {
      content: user.firstName + " " + user.lastName,
      href: getCompleteUrl(`editera/${user.accessProfileId}`),
    },
    telephoneNumber: formatPhoneNumber(user.mobileNumber),
    lastActivity: user.lastActivity ? user.lastActivity.split(" ")[0] : "",
    invitedBy: dataTranslations.t(user.invitedBy),
  }));
}

export const formatPhoneNumber = (mobilePhoneNumber?: string): string =>
  (mobilePhoneNumber || "")
    .replace(/^(?:0046|\+?46|46|0)/, "")
    .replace(/[\s-]/g, "")
    .replace(/^(\d)/, "0$1")
    .replace(
      /(\d{3})(\d{3})(\d{2})(\d)/, // All phone numbers does not seem like mobile numbers ...
      "$1-$2 $3 $4"
    );

export function handleRoles(roles: string[]): string {
  return roles
    .map((role) => {
      return getByKey(role)?.name ?? role;
    })
    .join(", ");
}

export type InviteStatus =
  | "NEW"
  | "SAVED"
  | "SENT"
  | "ACCEPTED"
  | "EXPIRED"
  | "LOCKED"
  | "CANCELED";

interface InviteStatusDictDef {
  [key: string]: { en: string; sv: string };
}

const InvitesStatusDict: InviteStatusDictDef = {
  LOCKED: { en: "New", sv: "Ny" },
  CANCELED: { en: "Canceled", sv: "Avbruten" },
  ACCEPTED: { en: "Accepted", sv: "Godkänd" },
  SENT: { en: "Sent", sv: "Skickad" },
};

/**
 * Check if we are missing structure access. This will manifest as having an org limitation with no orgs inside.
 *
 * @param {*} customerGroup The customer group structure given by the SRA API
 */
function isMissingStructureAccess(customerGroup: any) {
  return customerGroup && customerGroup.orgs && customerGroup.orgs.length === 0;
}

/**
 * Check if the customer group structure terminates on the customer group
 * level, meaning that access is given on the group level.
 *
 * @param {*} customerGroup The customer group structure given by the SRA API
 */
function isRestrictedOnCustomerGroup(customerGroup: any) {
  return customerGroup && !customerGroup.orgs;
}

/**
 * Check if the customer group structure terminates on the organization level,
 * meaning that access to specific organizations is explicitly given.
 *
 * @param {*} customerGroup The customer group structure given by the SRA API
 */
function isRestrictedOnOrganization(customerGroup: any) {
  return (
    customerGroup &&
    customerGroup.orgs &&
    customerGroup.orgs.filter((org: any) => org.units).length === 0
  );
}

/**
 * Check if the customer group structure terminates on the unit level,
 * meaning that access to a number of specific units is explicitly given.
 *
 * @param {*} customerGroup The customer group structure given by the SRA API
 */
function isRestrictedOnUnit(customerGroup: any) {
  return (
    customerGroup &&
    customerGroup.orgs &&
    customerGroup.orgs.filter(
      (org: any) => org.units && org.units.filter((unit: any) => unit.costCenters).length === 0
    ).length > 0
  );
}

/**
 * Check if the customer group structure terminates on the cost center level,
 * meaning that access to a number of specific cost centers is explicitly given.
 *
 * @param {*} customerGroup The customer group structure given by the SRA API
 */
function isRestrictedOnCostCenter(customerGroup: any) {
  return (
    customerGroup &&
    customerGroup.orgs &&
    customerGroup.orgs.filter(
      (org: any) => org.units && org.units.filter((unit: any) => unit.costCenters)
    )
  );
}
