import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import {
  ActionType,
  ColumnAction,
  EMPTY,
  ExportStatus,
  Filter,
  INITIAL_PAGE_SIZE,
  ManageTableProps,
  SortOrder,
} from "./types";
import { StyledPaginator } from "../StyledPaginator";
import { TeliaGridResizable } from "../StyledTable";
import { flushSync } from "react-dom";
import { sortTableEntries } from "./sort";
import { filterTableEntryList } from "./filter";
import { EXPORT_TYPE_CSV, KeyTableColumn } from "../../table/TableDefinitions";
import { B2xControlledTable } from "@telia/b2x-table/react-cjs";
import { ErrorMessage } from "../ErrorMessage";
import { ExportMessage } from "../ExportMessage";
import { action, category, label, trackEvent } from "@telia/b2b-web-analytics-wrapper";
import { exportDataAsCSV, exportDataAsXLSX } from "../../export/helper";
import { getColumnState, setColumnState } from "./columns";

export function ManageTable(props: ManageTableProps) {
  const {
    tableTexts,
    initialColumns,
    tableData,
    isLoading,
    isFetched,
    isError,
    showExportControl,
    exportAsCsvCallback,
    exportAsXlsxCallback,
    showExportMessages,
    exportFileNameWithoutType,
    exportWorksheetName,
    tableStateKey,
  } = props;
  const [sortedTableRows, setSortedTableRows] = useState<TableEntry[]>();
  const [sortedTableAllRows, setSortedTableAllRows] = useState<TableEntry[]>();
  const [visibleRows, setVisibleRows] = useState<TableEntry[]>();
  const paginationRef = useRef<{ currentPageIndex: number; pageSize: number }>({
    currentPageIndex: 1,
    pageSize: INITIAL_PAGE_SIZE,
  });
  const filterChangeTimer = useRef<NodeJS.Timeout>();
  const activeFiltersRef = useRef<Filter[]>();
  const [hasTableExpanded, setHasTableExpanded] = useState<boolean>(false);
  const [exportStatus, setExportStatus] = useState<ExportStatus>(ExportStatus.IDLE);

  const columnReducer = (
    state: TableManagerColumn[],
    action: ColumnAction
  ): TableManagerColumn[] => {
    const { columnIndex, actionType, columnList } = action;

    switch (actionType) {
      case ActionType.COLUMN_VISIBILITY_SYNC:
        handleColumnVisibility(state, columnList);
        break;
      case ActionType.COLUMN_VISIBILITY_RESET:
        resetColumnVisibility(state);
        break;
      case ActionType.ASCENDING:
      case ActionType.DESCENDING:
        handleColumnSortIcon(columnIndex, actionType, state);
        break;
      case ActionType.RESET:
        return getColumnState(initialColumns, tableStateKey);
        break;
    }
    return state;
  };

  const [columns, dispatchColumnReducer] = useReducer<
    (state: TableManagerColumn[], actions: ColumnAction) => TableManagerColumn[]
  >(columnReducer, getColumnState(initialColumns, tableStateKey));

  useEffect(() => {
    if (!tableData) {
      return;
    }

    const sortedTableData = sortTableEntries(tableData, 1, SortOrder.ASCENDING);
    // TODO: this should be the initial state
    dispatchColumnReducer({
      actionType: ActionType.RESET,
      columnIndex: -1,
      columnList: columns,
    });

    dispatchColumnReducer({
      actionType: ActionType.ASCENDING,
      columnIndex: 1,
      columnList: columns,
    });
    // original sorted set of rows
    setSortedTableAllRows(sortedTableData);
    // possibly filtered, sorted set of rows
    setSortedTableRows(sortedTableData);
    // paginated set of rows
    setVisibleRows(sortedTableData?.slice(0, INITIAL_PAGE_SIZE));
  }, [tableData]);

  useEffect(() => {
    if (sortedTableRows) {
      // If the current displayed amount of rows is reduced (by filtering)
      // to lower than the amount of current pages times the amount of rows per page,
      // the current visible page will be blank and the paginator will not show the current page you are on.
      // In the worst case you will not be able to even switch pages via the paginator (no other pages available).
      // This code below,  will set the visible page to index 1 (possibly you could be on a page > 1)
      // TODO: Calculate the max possible page index in case of rows reduced to > pageSize
      const { currentPageIndex, pageSize } = paginationRef.current;
      const currentMaxBoundary = currentPageIndex * pageSize;
      const currentMinBoundary = currentMaxBoundary - pageSize;
      const oob = sortedTableRows.length < currentMinBoundary;
      const virtualPageIndex = oob ? 1 : currentPageIndex;

      setVisibleRows(
        sortedTableRows.slice((virtualPageIndex - 1) * pageSize, virtualPageIndex * pageSize)
      );
    }
  }, [sortedTableRows]);

  useEffect(() => {
    addEventListener("resetButtonClick", onColumnResetHandler); // Listen to events emitted from inner component
    return () => {
      removeEventListener("resetButtonClick", onColumnResetHandler);
    };
  }, [initialColumns]);

  function handleColumnVisibility(
    state: TableManagerColumn[],
    columnList: TableManagerColumn[]
  ): void {
    const changedColumn = state.find((col, index) => col.isChecked !== columnList[index].isChecked);
    if (changedColumn) {
      // Toggle column visibility ...
      changedColumn.isChecked = !changedColumn.isChecked;
      setColumnState(state as KeyTableColumn[], tableStateKey);

      // Show column ...
      if (changedColumn.isChecked) {
        return;
      }

      // Check if hidden column has a filter ...
      const filterColumn = activeFiltersRef.current?.filter(
        (filter) => filter.column === changedColumn.title
      );
      if (filterColumn?.length) {
        // Reset the hidden column's filter ...
        manageFilters(changedColumn.title, EMPTY);
        applyActiveFilters();
        handleColumnSortIcon(-1, ActionType.COLUMN_VISIBILITY_SYNC, state); // Reset sort icon ...
      }
    }
  }

  function resetColumnVisibility(state: TableManagerColumn[]): void {
    // Reset all columns to default/initial visibility state ...
    state.forEach((col, index) => {
      col.isChecked = initialColumns[index].isChecked;
    });
    setColumnState(state as KeyTableColumn[], tableStateKey);
  }

  function handleColumnSortIcon(
    columnIndex: number | undefined,
    actionType: ActionType,
    state: TableManagerColumn[]
  ): void {
    state.forEach((col, index) => {
      if (col.sortable) {
        if (columnIndex === index) {
          col.sortable =
            actionType === ActionType.ASCENDING
              ? { sortIconName: "arrow-down" }
              : { sortIconName: "arrow-up" };
        } else {
          col.sortable = { sortIconName: "sorter" };
        }
      }
    });
  }

  const handleColumnClick = useCallback(
    (event: CustomEvent) => {
      const columnIndex = getIndexByName(event.detail);
      const sortMetaData = columns[columnIndex]?.sortable;
      let sortOrder: SortOrder;
      let actionType: ActionType;
      switch (sortMetaData?.sortIconName) {
        case "arrow-down":
          sortOrder = SortOrder.DESCENDING;
          actionType = ActionType.DESCENDING;
          break;
        case "sorter":
        case "arrow-up":
        default:
          sortOrder = SortOrder.ASCENDING;
          actionType = ActionType.ASCENDING;
          break;
      }

      flushSync(() => {
        dispatchColumnReducer({
          actionType: actionType,
          columnIndex: columnIndex,
          columnList: columns,
        });
        const result = sortTableEntries(sortedTableRows, columnIndex, sortOrder);
        const sortedFullTableData = sortTableEntries(sortedTableAllRows, columnIndex, sortOrder);
        setSortedTableRows([...result]);
        setSortedTableAllRows([...sortedFullTableData]);
      });
    },
    [sortedTableRows, sortedTableAllRows, columns]
  );

  function getIndexByName(columnName: string) {
    return initialColumns.findIndex((c) => c.title === columnName);
  }

  function noFiltersActive() {
    return activeFiltersRef.current?.length === 0;
  }

  function applyActiveFilters() {
    if (!sortedTableRows) {
      return;
    }

    if (noFiltersActive()) {
      setSortedTableRows(sortedTableAllRows);
      return;
    }

    let filteredResult = sortedTableAllRows;
    activeFiltersRef.current?.forEach((activeFilter) => {
      const columnIndex = getIndexByName(activeFilter.column);
      const filterValue = activeFilter.value.toLowerCase().trim();
      filteredResult = filterTableEntryList(filteredResult ?? [], columnIndex, filterValue);
    });

    setSortedTableRows(filteredResult);
  }

  function manageFilters(columnName: string, columnValue: string) {
    const currentFilters = activeFiltersRef.current ?? [];
    const activeFilterForCurrentColumn = currentFilters.find((x) => x.column === columnName);
    if (columnValue === EMPTY) {
      // remove the filter if there was a filter for this column
      activeFiltersRef.current = currentFilters.filter((x) => x.column != columnName);
    } else if (activeFilterForCurrentColumn) {
      // filter exists already, mutate value (it's referenced)
      activeFilterForCurrentColumn.value = columnValue;
    } else {
      // no previous filter exists for this column, append a new
      const prev = activeFiltersRef.current ?? [];
      activeFiltersRef.current = [...prev, { column: columnName, value: columnValue }];
    }
  }
  function filterRows(event: CustomEvent) {
    const columnName = event.detail.column;
    const columnValue = event.detail.value;
    // keep filters as reference
    manageFilters(columnName, columnValue);
    // filter the rows
    applyActiveFilters();
  }

  const handleFilterChange = useCallback(
    (event: CustomEvent) => {
      if (event.detail.value === EMPTY) {
        // Clear filter action ...
        manageFilters(event.detail.column, event.detail.value);
      }
      // Debounce inputs arriving in fast succession
      clearTimeout(filterChangeTimer.current);
      filterChangeTimer.current = setTimeout(() => {
        return filterRows(event);
      }, 150);
    },
    [sortedTableRows]
  );

  const onExportData = useCallback(
    async (event: CustomEvent): Promise<void> => {
      const exportAsLabel: string = event.detail;

      if (exportStatus === ExportStatus.ERROR) {
        setExportStatus(ExportStatus.IDLE); // retry - if error last run
      }
      if (exportStatus === ExportStatus.RUNNING_CSV || exportStatus == ExportStatus.RUNNING_XLSX) {
        return;
      }

      trackEvent(category.USERS, action.INITIATED, label.EXPORT_USERLIST).finally();

      try {
        if (exportAsLabel.includes(`.${EXPORT_TYPE_CSV}`)) {
          setExportStatus(ExportStatus.RUNNING_CSV);
          if (exportAsCsvCallback) {
            await exportAsCsvCallback?.();
          } else {
            // default FE export of table data
            await exportDataAsCSV(
              columns,
              sortedTableRows ?? [],
              exportFileNameWithoutType ? exportFileNameWithoutType + ".csv" : "data.csv"
            );
          }
          setExportStatus(ExportStatus.IDLE);
        } else {
          setExportStatus(ExportStatus.RUNNING_XLSX);
          if (exportAsXlsxCallback) {
            await exportAsXlsxCallback?.();
          } else {
            // default FE export of table data
            await exportDataAsXLSX(
              columns as KeyTableColumn[],
              sortedTableRows ?? [],
              exportFileNameWithoutType ? exportFileNameWithoutType + ".xlsx" : "data.xlsx",
              exportWorksheetName ? exportWorksheetName : "Data"
            );
          }
          setExportStatus(ExportStatus.IDLE);
        }
      } catch (error) {
        setExportStatus(ExportStatus.ERROR);
      }
    },
    [sortedTableRows]
  );

  const handleExportControlVisibility = useCallback(() => {
    if (showExportControl) {
      return {
        exportBtnLabel: tableTexts.exportLabelText,
        exportTypes: tableTexts.EXPORT_TYPES,
      };
    } else {
      return;
    }
  }, [showExportControl]);

  const onPaginationChange = useCallback(
    (event: CustomEvent) => {
      if (!sortedTableRows) {
        return;
      }
      const { page, pageSize } = event.detail;
      paginationRef.current = { currentPageIndex: page, pageSize: pageSize };
      setVisibleRows(sortedTableRows?.slice((page - 1) * pageSize, page * pageSize));
    },
    [sortedTableRows]
  );

  function onColumnResetHandler() {
    dispatchColumnReducer({
      actionType: ActionType.COLUMN_VISIBILITY_RESET,
      columnIndex: -1, // N/A
      columnList: [], // N/A
    });
  }

  function onColumnManagerSelection(event: CustomEvent): void {
    if (event.detail) {
      dispatchColumnReducer({
        actionType: ActionType.COLUMN_VISIBILITY_SYNC,
        columnIndex: -1, // N/A
        columnList: event.detail as TableManagerColumn[],
      });
    }
  }

  const showMessageHandler = useCallback(() => {
    if (isFetched && (!sortedTableAllRows || sortedTableAllRows.length === 0)) {
      return tableTexts.tableMessage;
    } else {
      return undefined;
    }
  }, [sortedTableAllRows, isFetched]);

  return (
    <TeliaGridResizable fullwidth={hasTableExpanded}>
      {isError && <ErrorMessage />}
      {showExportMessages && <ExportMessage exportStatus={exportStatus} />}

      <B2xControlledTable
        message={showMessageHandler()}
        columns={columns}
        data={visibleRows ?? []}
        tableRowsCount={sortedTableRows?.length}
        tableRowsLimit={visibleRows?.length}
        isLoading={isLoading}
        isError={isError}
        onColumnClick={handleColumnClick}
        onFilterColumnInput={handleFilterChange}
        {...handleExportControlVisibility()}
        onExportData={onExportData}
        onTableExpandClicked={() => setHasTableExpanded((prev) => !prev)}
        isWideTable={hasTableExpanded}
        showFilterRow
        columnFilterLabel={tableTexts.chooseColumnLabelText}
        clearFiltersButtonDisabled={isLoading}
        showClearFiltersButton
        showExpandButton
        resetColumnLabel={tableTexts.resetColumnLabelText}
        onColumnManagerSelection={onColumnManagerSelection}
      />

      <StyledPaginator
        showPageSizeSelector={true}
        listLength={sortedTableRows?.length}
        pageSizes={`[${INITIAL_PAGE_SIZE}, 25, 50, 100]`}
        data-testid="paginator"
        onPaginationChange={onPaginationChange}
      />
    </TeliaGridResizable>
  );
}
