import { useMessage } from "@messageformat/react";
import { Column, KeyTableColumn } from "../table/TableDefinitions";
import { UserProfilesData } from "./useUsers";

export function useTableColumns(
  messageKey: string,
  columns: Column[],
  profilesData?: UserProfilesData
): KeyTableColumn[] {
  const columnMessageKey = `${messageKey}.columns`;
  const columnMessages = useMessage(columnMessageKey);

  const getColIdx = (nameKey: string): number => {
    return columns.findIndex((col) => col.shortTextKey === nameKey);
  };

  if (profilesData) {
    if (!profilesData.enableUnits) {
      columns.splice(getColIdx("units"), 1);
    }
    if (!profilesData.enableCostCenters) {
      columns.splice(getColIdx("cost-locations"), 1);
    }
    if (!profilesData.enableAgreements) {
      columns.splice(getColIdx("agreements"), 1);
    }
    if (!profilesData.enableVpns) {
      columns.splice(getColIdx("vpns"), 1);
    }
    if (!profilesData.enableFederatedUsers) {
      columns.splice(getColIdx("login-method"), 1);
    }
  }

  return columns.map((col) => {
    return {
      nameKey: col.shortTextKey,
      title: columnMessages[col.shortTextKey](),
      type: col.type,
      sortable: col.sortable ? { sortIconName: "sorter" } : undefined,
      filterable: col.filterable ? { label: "", type: "text" } : undefined,
      isChecked: col.defaultVisible,
      disabled: !!col.nonRemovable,
    };
  });
}
