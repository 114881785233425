import React from "react";
import { B2xModal } from "@telia/b2x-modal/react-cjs";
import { TeliaButton } from "@teliads/components/react";
import { useMessage } from "@messageformat/react";
import { spacing } from "@teliads/components/foundations";
import styled from "styled-components";

const StyledModal = styled(B2xModal)`
  #cancel-invite-for-user {
    border-radius: ${spacing.spacing16};
  }
`;
interface CancelInvitationModalProps {
  name: string | undefined;
  isOpen: boolean;
  closeModal: () => void;
  cancelInvite: () => void;
}

export function CancelInvitationModal({
  name,
  isOpen,
  closeModal,
  cancelInvite,
}: CancelInvitationModalProps) {
  const heading = useMessage("invite.manage.dialog.heading");
  const description = useMessage("invite.manage.dialog.description", { name: name });
  const ok = useMessage("invite.manage.dialog.ok");
  const cancel = useMessage("invite.manage.dialog.cancel");
  return (
    <StyledModal
      heading={heading}
      description={`${description}`}
      hideCloseButton
      modalId="cancel-invite-for-user"
      role="alertdialog"
      isOpen={isOpen}
      onCloseModal={closeModal}
    >
      <div slot="button-footer" style={{ padding: spacing.spacing24 }}>
        <TeliaButton variant="primary" size="md" onClick={cancelInvite}>
          {ok}
        </TeliaButton>
        <TeliaButton variant="text" onClick={closeModal} style={{ marginLeft: spacing.spacing16 }}>
          {cancel}
        </TeliaButton>
      </div>
    </StyledModal>
  );
}
