import { useQuery } from "@tanstack/react-query";
import { useScopeDetails } from "./useScopeDetails";
import {
  GetInvitesResponseDTO,
  InviteControllerService,
  InviteDTO
} from "@telia/b2b-rest-client/src/corp-invite-service";
import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { formatPhoneNumber, handleCustomerGroup, handleInviteStatus, handleRoles } from "../util";
import { useMessage } from "@messageformat/react";
import { DataTranslations, useDataTranslations } from "./useDataTranslations";

export function useInvites() {
  const { data: scopeDetails, isFetched: scopeIsFetched } = useScopeDetails();
  const buttonText = useMessage("invite.manage.button.text");
  const scopeId = scopeDetails?.id;
  const groupId = scopeDetails?.details.scope?.groupId;

  const dataTranslations = useDataTranslations();

  return useQuery<TableEntry[]>({
    queryKey: ["invites", scopeId, groupId],
    queryFn: queryFunction,
    enabled: scopeIsFetched && !!dataTranslations,
  });

  function queryFunction(): Promise<TableEntry[]> {
    if (!scopeId || !groupId) {
      return Promise.reject();
    }

    return InviteControllerService.getInvitesOnGroup(scopeId, groupId)
      .then((response: GetInvitesResponseDTO) =>
        mapInviteTableRows(response.invites, dataTranslations)
      )
      .catch(() => Promise.reject());
  }

  function mapInviteTableRows(
    invites: InviteDTO[],
    dataTranslations: DataTranslations
  ): TableEntry[] {
    return invites.map((invite) => [
      invite.recipientFullName,
      dataTranslations.t(invite.createdByFullName),
      handleRoles(dataTranslations.tList(invite.roles)),
      handleCustomerGroup(invite.customerGroup, dataTranslations),
      handleInviteStatus(invite.status),
      invite.recipientEmail ?? "",
      formatPhoneNumber(invite.recipientPhoneNumber) ?? "",
      {
        href: "#",
        content: "",
        rowNumber: invite.id,
        attrs: {
          text: buttonText,
          size: "sm",
          variant: "text",
          leftIcon: { name: "delete", size: "sm" },
        },
      },
    ] as TableEntry);
  }
}
