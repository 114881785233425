import React, { useCallback, useEffect, useRef, useState } from "react";

import { INVITE_TABLE_COLUMNS, INVITE_TABLE_STATE_KEY } from "./types";
import { useTableColumns } from "../../hooks/useTableColumns";
import { useInvites } from "../../hooks/useInvites";
import { ManageTable } from "../ManageTable";
import { useTableTexts } from "../../hooks/useTableTexts";
import { CancelInvitationModal } from "../CancelInvitationModal";
import { useCancelInviteMutation } from "../../hooks/useCancelInviteMutation";
import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { useMessage } from "@messageformat/react";
import { ManageInvitationsOnboarding } from "../ManageInvitationsOnboarding";
import { OboardingButton, OnboardingButtonText } from "../OboardingButton";

export function ManageInvitations() {
  const {
    data: invitesData,
    isLoading: invitesLoading,
    isError: invitesError,
    isFetched: invitesFetched,
  } = useInvites();
  const cancelInvite = useCancelInviteMutation();
  const inviteRef = useRef<{ inviteId: number; name: string | undefined }>();
  const tableTexts = useTableTexts();
  const exportWorksheetName = useMessage("tab.invites");
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const initialColumns = useTableColumns("table.invites", INVITE_TABLE_COLUMNS);
  const [showOnboarding, setShowOnboarding] = useState<boolean | undefined>();

  useEffect(() => {
    if (!invitesData) {
      return;
    }
    addEventListener("buttonClicked", handleButtonClicked);
    return () => {
      removeEventListener("buttonClicked", handleButtonClicked);
    };
  }, [invitesData]);

  function handleButtonClicked(event: Event) {
    const customEvent = event as CustomEvent;
    const details = customEvent.detail as { buttonText: string; rowNumber: number };

    const foundEntry = invitesData?.find((tableEntry: TableEntry) => {
      const { rowNumber } = tableEntry[5] as { rowNumber: number };
      return rowNumber === details.rowNumber;
    });
    inviteRef.current = { inviteId: details.rowNumber, name: foundEntry?.[0].toString() ?? "" };
    setShowCancelModal(true);
  }

  const handleClose = useCallback(() => {
    setShowCancelModal(false);
  }, [setShowCancelModal]);

  const handleCancelInvite = useCallback(() => {
    setShowCancelModal(false);
    cancelInvite({ inviteId: inviteRef.current?.inviteId });
  }, [setShowCancelModal, cancelInvite]);

  const handleShowOnboarding = useCallback(() => {
    setShowOnboarding(!showOnboarding);
  }, [showOnboarding, setShowOnboarding]);

  return (
    <>
      <OboardingButton variant="tertiary-purple" onClick={handleShowOnboarding}>
        <OnboardingButtonText viewName="manageInvitations" />
      </OboardingButton>
      <ManageTable
        tableTexts={tableTexts}
        initialColumns={initialColumns}
        tableData={invitesData}
        isLoading={invitesLoading}
        isError={invitesError}
        isFetched={invitesFetched}
        showExportControl={true}
        showExportMessages={true}
        exportFileNameWithoutType={"invites"}
        exportWorksheetName={exportWorksheetName}
        tableStateKey={INVITE_TABLE_STATE_KEY}
      />
      <CancelInvitationModal
        isOpen={showCancelModal}
        closeModal={handleClose}
        cancelInvite={handleCancelInvite}
        name={inviteRef.current?.name}
      />
      <ManageInvitationsOnboarding showOnboarding={showOnboarding}/>
    </>
  );
}
