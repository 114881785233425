import React, { useEffect, useState } from "react";
import { MessageObject, MessageProvider } from "@messageformat/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { currentLanguage } from "@telia/b2b-i18n";
import { logError } from "@telia/b2x-logging";

import { MainView } from "../MainView";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-react";
import FlagProvider from "@unleash/proxy-client-react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false, // set in local query when you need this
    },
  },
});

export function Scaffolding() {
  const { client } = useUnleashClientWithScopeId("b2b-manage-users");
  const [messages, setMessages] = useState<MessageObject>();

  useEffect(() => {
    (async () => {
      try {
        const messages = await import(`../../locales/locale.${currentLanguage()}.yaml`);
        setMessages(messages.default);
      } catch (error) {
        logError("b2b-manage-users", `Error while initiating locale support: ${error}`).then();
      }
    })();
  }, []);

  if (!messages) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <MessageProvider locale={currentLanguage()} messages={messages}>
        {client && (
          <FlagProvider unleashClient={client} startClient={false}>
            <MainView />
          </FlagProvider>
        )}
      </MessageProvider>
    </QueryClientProvider>
  );
}
