import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { Link } from "./types";

export function filterTableEntryList(
  rows: TableEntry[],
  columnIndex: number,
  filterValue: string
): TableEntry[] {
  return rows.filter((entry: TableEntry) => {
    const cellValue = entry[columnIndex] !== null ? entry[columnIndex] : "";
    const value = cellValue.valueOf();
    if (typeof value !== "object") {
      return value.toString().toLowerCase().includes(filterValue.toLowerCase());
    } else {
      const link = value as Link;
      return link.content.toLowerCase().includes(filterValue.toLowerCase());
    }
  });
}
