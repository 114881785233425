import React from "react";
import styled from "styled-components";
import { spacing } from "@teliads/components/foundations";
import { useMessage } from "@messageformat/react";
import { TeliaP, TeliaSkeleton } from "@teliads/components/react";
import { B2xSpinner } from "@telia/b2x-spinner/react-cjs";

const ExportInfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${spacing.spacing4};
`;

const SkeletonBar = styled(TeliaSkeleton)`
  width: 8rem;
  height: 1.8rem;
  margin-left: 1rem;
`;

type ExportInfoProps = {
  exportType: string;
};

export function ExportInfo({ exportType }: ExportInfoProps) {
  const text = useMessage(`export-info.${exportType}`);
  return (
    <ExportInfoContainer>
      <TeliaP variant={"paragraph-100"}>{text}</TeliaP>
      <B2xSpinner size="small" style={{ marginLeft: "1rem", alignSelf: "baseline" }} />
    </ExportInfoContainer>
  );
}
