import { KeyTableColumn } from "../../table/TableDefinitions";
import { ColumnState } from "./types";

export function setColumnState(state: KeyTableColumn[], tableStateKey: string | undefined): void {
  if (window.localStorage && tableStateKey) {
    window.localStorage.setItem(
      tableStateKey,
      JSON.stringify(
        state.map((col) => {
          return { nameKey: col.nameKey, isChecked: col.isChecked };
        })
      )
    );
  }
}

export function getColumnState(
  initialColumns: KeyTableColumn[],
  tableStateKey: string | undefined
): KeyTableColumn[] {
  let state = null;
  if (window.localStorage && tableStateKey) {
    state = window.localStorage.getItem(tableStateKey);
    if (state) {
      const stateArr: ColumnState[] = JSON.parse(state);
      return initialColumns.map((col) => {
        const colState = stateArr.find((colState) => colState.nameKey === col.nameKey);
        return colState ? { ...col, isChecked: colState.isChecked } : { ...col };
      });
    }
  }
  return initialColumns;
}
