import React from "react";
import styled from "styled-components";
import { useMessage } from "@messageformat/react";

import { TeliaButton } from "@teliads/components/react";

export const OboardingButton = styled(TeliaButton)`
  margin-left: calc(50% - 46ch);
`;

export function OnboardingButtonText({
  viewName,
}: {
  viewName: "manageUsers" | "manageInvitations";
}) {
  const onboardingButtonText = useMessage(`onboarding.${viewName}.buttonText`);
  const onboardingButtonPage = useMessage(`onboarding.${viewName}.buttonPage`);

  return (
    <>
      {onboardingButtonText}&nbsp;<u>{onboardingButtonPage}</u>
    </>
  );
}
