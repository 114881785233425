import React from "react";
import styled from "styled-components";
import { spacing } from "@teliads/components/foundations";
import { useMessage } from "@messageformat/react";
import { TeliaNotification } from "@teliads/components/react";

const ErrorContainer = styled.div`
  margin: auto auto ${spacing.spacing24} auto;
`;

export function ErrorMessage() {
  const text = useMessage("table.error-message");
  return (
    <ErrorContainer>
      <TeliaNotification status={"error"} headingTag={"h3"}>
        <span slot={"heading"}>{text}</span>
      </TeliaNotification>
    </ErrorContainer>
  );
}
