import { useMessage } from "@messageformat/react";
import { EXPORT_TYPE_CSV, EXPORT_TYPE_XLSX } from "../table/TableDefinitions";

export function useTableTexts() {
  return {
    exportLabelText: useMessage("table.control.export-label"),
    EXPORT_TYPES: JSON.stringify([
      useMessage("table.control.export-as-format", { FORMAT: EXPORT_TYPE_CSV }),
      useMessage("table.control.export-as-format", { FORMAT: EXPORT_TYPE_XLSX }),
    ]),
    resetColumnLabelText: useMessage("table.control.reset-column-label"),
    chooseColumnLabelText: useMessage("table.control.choose-column-label"),
    tableMessage: useMessage("table.control.message"),
  };
}
