import React, { useCallback, useState } from "react";
import { TeliaButton } from "@teliads/components/react";
import {
  KeyTableColumn,
  USER_TABLE_COLUMNS,
  USER_TABLE_STATE_KEY,
} from "../../table/TableDefinitions";
import { useTableTexts } from "../../hooks/useTableTexts";
import { ManageTable } from "../ManageTable";
import { useTableColumns } from "../../hooks/useTableColumns";
import { useUsers } from "../../hooks/useUsers";
import { useMessage } from "@messageformat/react";
import { ManageUsersOnboarding } from "../ManageUsersOnboarding";
import { OboardingButton, OnboardingButtonText } from "../OboardingButton";

export function ManageUsers() {
  const tableTexts = useTableTexts();
  const exportWorksheetName = useMessage("tab.users");
  const [showOnboarding, setShowOnboarding] = useState<boolean | undefined>();
  const [initialColumns, setInitialColumns] = useState<KeyTableColumn[]>([]);
  const { data: userProfilesData, isLoading, isError, isFetched } = useUsers();
  const initiatedColumns = useTableColumns("table.user", USER_TABLE_COLUMNS, userProfilesData);

  if (isFetched && initialColumns.length === 0) {
    setInitialColumns(initiatedColumns); // rerender !
  }

  const handleShowOnboarding = useCallback(() => {
    setShowOnboarding(!showOnboarding);
  }, [showOnboarding, setShowOnboarding]);

  return (
    <>
      <OboardingButton variant="tertiary-purple" onClick={handleShowOnboarding}>
        <OnboardingButtonText viewName="manageUsers" />
      </OboardingButton>
      <ManageTable
        tableTexts={tableTexts}
        tableData={userProfilesData?.userList}
        initialColumns={initialColumns}
        isFetched={isFetched}
        isLoading={isLoading}
        isError={isError}
        showExportControl={true}
        showExportMessages={true}
        exportFileNameWithoutType={"users"}
        exportWorksheetName={exportWorksheetName}
        tableStateKey={USER_TABLE_STATE_KEY}
      />
      <ManageUsersOnboarding showOnboarding={showOnboarding} />
    </>
  );
}
