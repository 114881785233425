import { useMutation, useQueryClient } from "@tanstack/react-query";
import { corpInviteService } from "@telia/b2b-rest-client";
import { logError, logInfo } from "@telia/b2x-logging";
import { useScopeDetails } from "./useScopeDetails";

export function useCancelInviteMutation() {
  const { data: scopeDetails } = useScopeDetails();
  const queryClient = useQueryClient();
  const { mutate: cancelInvite } = useMutation({
    mutationKey: ["cancelInvite"],
    mutationFn: cancelInviteWorker,
    onSuccess: invalidateInvitesQuery,
  });

  function invalidateInvitesQuery() {
    queryClient
      .invalidateQueries({
        queryKey: [
          "invites",
          scopeDetails?.details.scope?.id,
          scopeDetails?.details.scope?.groupId,
        ],
      })
      .finally();
  }

  async function cancelInviteWorker(cancelProps: { inviteId: number | undefined }) {
    const { inviteId } = cancelProps;
    const scopeId = scopeDetails?.details.scope?.id;
    const groupId = scopeDetails?.details.scope?.groupId;
    if (!inviteId || !scopeId || !groupId) {
      return Promise.reject();
    }
    try {
      await corpInviteService.InviteControllerService.cancelInvite(scopeId, groupId, inviteId);
      logInfo("b2b-manage-users", `Successfully cancelled invite ${inviteId}`).finally();
      return Promise.resolve();
    } catch (reason: unknown) {
      logError("b2b-manage-users", `Could not cancel invite ${inviteId}: ${reason}`).finally();
      return Promise.reject();
    }
  }

  return cancelInvite;
}
