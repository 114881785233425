import { Column } from "../../table/TableDefinitions";

export const INVITE_TABLE_STATE_KEY = "MANAGE-INVITES-TABLE";
export const INVITE_TABLE_COLUMNS: Column[] = [
  {
    textKey: "name",
    shortTextKey: "name",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "invited-by",
    shortTextKey: "invited-by",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "roles",
    shortTextKey: "roles",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "organisations",
    shortTextKey: "organisations",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "status",
    shortTextKey: "status",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "e-mail",
    shortTextKey: "e-mail",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "telephone-number",
    shortTextKey: "telephone-number",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "manage",
    shortTextKey: "manage",
    type: "button",
    sortable: false,
    filterable: false,
    defaultVisible: true,
  },
];
