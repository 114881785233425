import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useMessage } from "@messageformat/react";
import { spacing } from "@teliads/components/foundations";
import colors from "@teliads/components/foundations/colors/variables.js";
import {
  TeliaCol,
  TeliaCtaLink,
  TeliaGrid,
  TeliaHeading,
  TeliaRow,
  TeliaTab,
  TeliaTabContent,
} from "@teliads/components/react";

import { ManageUsers } from "./ManageUsers";
import { ManageInvitations } from "./ManageInvitations";
import { action, category, label, trackEvent } from "@telia/b2b-web-analytics-wrapper";
import { getCompleteUrl } from "../util";
import { getGa4ManageInviteParams, getGa4StepParams, trackGa4 } from "@telia/b2b-analytics";
import { TabChangeDetail } from "@teliads/components/dist/types/components/telia-tab/telia-tab";
import { isTestEnv } from "@telia/b2b-utils";

const HeaderContainer = styled.div`
  background-color: ${colors.gray50};
  padding-bottom: 4.75rem;
`;

const HeaderContent = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.spacing64} 0;
`;

const TeliaColDynamic = styled(TeliaCol)`
  width: 100%;
`;

const TabsContent = styled.div`
  margin: -4.75rem auto auto auto;
  padding: 0;
`;

const TableContainer = styled.div`
  margin: ${spacing.spacing32} 0 ${spacing.spacing48} 0;
`;

const enum Tab {
  MANAGE_USERS = "MANAGE_USERS",
  MANAGE_INVITES = "MANAGE_INVITES",
}

export function MainView() {
  const pageTitle = useMessage("page-title");
  const inviteButtonText = useMessage("invite.button-text");
  const tabUsersText = useMessage("tab.users");
  const tabInvitesText = useMessage("tab.invites");

  const [activeTab, setActiveTab] = useState<Tab>(Tab.MANAGE_USERS);

  // Note this Voca component is deprecated - switch to Purpur
  const handleTabSelection = (event: CustomEvent<TabChangeDetail>) => {
    if (event.detail.value === Tab.MANAGE_USERS) {
      trackEvent(category.USERS, action.OPEN, label.TAB_USER).finally();
    } else {
      trackEvent(category.USERS, action.OPEN, label.TAB_INVITES).finally();
    }

    setActiveTab(event.detail.value as Tab);
  };

  const onInviteButtonClick = useCallback(() => {
    trackEvent(category.USERS, action.INITIATED, label.INVITE_USER).finally();

    trackGa4("user_invitation_initiate", {
      ...getGa4ManageInviteParams(),
      ...getGa4StepParams("user_invitation_initiate", 1),
    });
    // proceed propagation ...
  }, []);

  return (
    <div>
      <HeaderContainer>
        <TeliaGrid>
          <TeliaRow>
            <TeliaColDynamic>
              <HeaderContent>
                <TeliaHeading tag="h1" variant="title-300">
                  {pageTitle}
                </TeliaHeading>
                <TeliaCtaLink
                  href={getCompleteUrl("bjudin")}
                  variant="primary"
                  onClick={onInviteButtonClick}
                >
                  {inviteButtonText}
                </TeliaCtaLink>
              </HeaderContent>
            </TeliaColDynamic>
          </TeliaRow>
        </TeliaGrid>
      </HeaderContainer>
      <TeliaGrid>
        <TeliaRow>
          <TeliaColDynamic>
            <TabsContent>
              <TeliaTab onVocaTabChange={handleTabSelection} variant={"wide"}>
                <TeliaTabContent tabId={Tab.MANAGE_USERS} name={tabUsersText} />
                <TeliaTabContent tabId={Tab.MANAGE_INVITES} name={tabInvitesText} />
              </TeliaTab>
            </TabsContent>
          </TeliaColDynamic>
        </TeliaRow>
      </TeliaGrid>
      <TableContainer>
        {activeTab === Tab.MANAGE_USERS && <ManageUsers />}
        {activeTab === Tab.MANAGE_INVITES && <ManageInvitations />}
      </TableContainer>
    </div>
  );
}
