import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";

import { KeyTableColumn } from "../../table/TableDefinitions";

export type ColumnState = {
  nameKey: string;
  isChecked: boolean;
};
export interface ManageTableProps {
  tableTexts: any;
  initialColumns: KeyTableColumn[];
  tableData?: TableEntry[];
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  showExportControl?: boolean;
  exportAsCsvCallback?: () => Promise<void>;
  exportAsXlsxCallback?: () => Promise<void>;
  showExportMessages?: boolean;
  exportFileNameWithoutType?: string;
  exportWorksheetName?: string;
  tableStateKey?: string;
}
export interface Link {
  content: string;
  href: string;
}
export enum ExportStatus {
  IDLE,
  RUNNING_CSV,
  RUNNING_XLSX,
  ERROR,
}

export enum SortOrder {
  ASCENDING = "arrow-up",
  DESCENDING = "arrow-down",
}

export enum ActionType {
  ASCENDING = "arrow-up",
  DESCENDING = "arrow-down",
  COLUMN_VISIBILITY_SYNC = "column-visibility-sync",
  COLUMN_VISIBILITY_RESET = "column-visibility-reset",
  RESET = "reset",
}

export type ColumnAction = {
  actionType: ActionType;
  columnIndex: number;
  columnList: TableManagerColumn[];
};

export interface Filter {
  column: string;
  value: string;
}

export const INITIAL_PAGE_SIZE = 10;
export const EMPTY = "";
