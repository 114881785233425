import { useQuery } from "@tanstack/react-query";
import { getScopeFromUrl } from "@telia/b2b-customer-scope";
import { corpScopeInformation } from "@telia/b2b-rest-client";
import { GetScopeDetailsResponse } from "@telia/b2b-rest-client/dist/corp-scope-information";

export interface ScopeDetails {
  id: string;
  details: GetScopeDetailsResponse;
}
export function useScopeDetails() {
  const scopeId: string | null = getScopeFromUrl(window.location.pathname);

  return useQuery<ScopeDetails>({
    queryKey: ["scope-details", scopeId],
    queryFn: () => worker(scopeId),
    enabled: !!scopeId,
  });
}

function worker(scopeId: string | null): Promise<ScopeDetails> {
  if (scopeId) {
    return corpScopeInformation.ScopeControllerService.getScopeDetails(scopeId).then((response) => {
      return {
        id: scopeId,
        details: response,
      };
    });
  }
  return Promise.reject();
}
