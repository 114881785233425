import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { Link, SortOrder } from "./types";

function sortAscending(a: string, b: string): number {
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
}
function sortDescending(a: string, b: string): number {
  if (a === b) {
    return 0;
  }
  return a > b ? -1 : 1;
}
function sort(a: string, b: string, sortOrder: SortOrder): number {
  if (sortOrder === SortOrder.ASCENDING) {
    return sortAscending(a, b);
  } else {
    return sortDescending(a, b);
  }
}

export function sortTableEntries(
  tableEntries: TableEntry[] | undefined,
  sortByColumnIndex: number,
  sortOrder: SortOrder
): TableEntry[] {
  if (!tableEntries) {
    return [];
  }
  return tableEntries.sort((a, b) => {
    let aValue: string, bValue: string;
    const aColumn = a[sortByColumnIndex] !== null ? a[sortByColumnIndex] : "";
    const bColumn = b[sortByColumnIndex] !== null ? b[sortByColumnIndex] : "";
    if (typeof aColumn !== "object") {
      aValue = aColumn.toString().trim().toLowerCase();
      bValue = bColumn.toString().trim().toLowerCase();
    } else {
      const aLink = aColumn as Link;
      aValue = aLink.content.trim().toLowerCase();
      const bLink = bColumn as Link;
      bValue = bLink.content.trim().toLowerCase();
    }
    return sort(aValue, bValue, sortOrder);
  });
}
