import styled from "styled-components";
import { spacing } from "@teliads/components/foundations";
import { ExportInfo } from "../ExportInfo";
import { EXPORT_TYPE_CSV, EXPORT_TYPE_XLSX } from "../../table/TableDefinitions";
import { ExportError } from "../ExportError";
import React from "react";
import { ExportStatus } from "../ManageTable/types";

const ExportMessageContainer = styled.div`
  margin-bottom: ${spacing.spacing16};
  width: fit-content;
`;

export function ExportMessage({ exportStatus }: { exportStatus: ExportStatus }) {
  if (exportStatus === ExportStatus.IDLE) {
    return null;
  }

  return (
    <ExportMessageContainer>
      {exportStatus === ExportStatus.RUNNING_CSV && <ExportInfo exportType={EXPORT_TYPE_CSV} />}
      {exportStatus === ExportStatus.RUNNING_XLSX && <ExportInfo exportType={EXPORT_TYPE_XLSX} />}
      {exportStatus === ExportStatus.ERROR && <ExportError />}
    </ExportMessageContainer>
  );
}
