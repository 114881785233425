import { useMessage } from "@messageformat/react";

// Translates known keys in the data to text (see the Map)
export class DataTranslations {
  translationsMap: Map<string, any>;

  constructor(translationsMap: Map<string, any>) {
    this.translationsMap = translationsMap;
  }

  t(data: string): string {
    return this.translationsMap.get(data) ?? data;
  }

  tList(list: Array<string>): string[] {
    return list.map(data => this.t(data));
  }
}

export function useDataTranslations(): DataTranslations {
  return new DataTranslations(new Map([
    ["ALL", useMessage("data-translation-key.ALL")],
    ["NO_ACCESS", useMessage("data-translation-key.NO_ACCESS")],
    ["TELIA_SUPPORT", useMessage("data-translation-key.TELIA_SUPPORT")]
  ]));
}
