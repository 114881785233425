import { useQuery } from "@tanstack/react-query";
import { corpSraMybusinessFacade } from "@telia/b2b-rest-client";

import { useScopeDetails } from "./useScopeDetails";
import { currentLanguage } from "@telia/b2b-i18n";
import { mapUserDataList } from "../util";
import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { useDataTranslations } from "./useDataTranslations";
import { useFlag } from "@unleash/proxy-client-react";

export type UserProfilesData = {
  userList: TableEntry[];
  enableUnits: boolean;
  enableCostCenters: boolean;
  enableAgreements: boolean;
  enableVpns: boolean;
  enableFederatedUsers: boolean;
};

export function useUsers() {
  const { data: scopeDetails, isFetched: scopeIsFetched } = useScopeDetails();

  const scopeId = scopeDetails?.id;
  const groupId = scopeDetails?.details.scope?.groupId;

  const dataTranslations = useDataTranslations();

  return useQuery<UserProfilesData>({
    queryKey: ["user-data", scopeDetails?.id, scopeDetails?.details.scope?.groupId],
    queryFn: queryFunction,
    enabled: scopeIsFetched && !!dataTranslations,
    retry: 1,
  });

  async function queryFunction(): Promise<UserProfilesData> {
    if (!scopeId || !groupId) {
      return Promise.reject();
    }

    try {
      const profilesInfo =
        await corpSraMybusinessFacade.UserControllerService.getUsersInCustomerGroupJson(
          scopeId,
          groupId
        );
      const userList = mapUserDataList(dataTranslations, profilesInfo);

      return {
        userList,
        enableUnits: profilesInfo.enableUnits,
        enableCostCenters: profilesInfo.enableCostCenters,
        enableAgreements: profilesInfo.enableAgreements,
        enableVpns: profilesInfo.enableVpns,
        enableFederatedUsers: profilesInfo.enableFederatedUsers,
      };
    } catch (error) {
      throw new Error("ERROR: Something went wrong loading users...");
    }
  }
}
