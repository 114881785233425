import { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";

export interface KeyTableColumn extends TableManagerColumn {
  nameKey: string;
}

export interface Column {
  textKey: string;
  shortTextKey: string;
  type: any;
  sortable?: boolean;
  filterable?: boolean;
  nonRemovable?: boolean;
  defaultVisible: boolean;
  content?: string;
}

export const USER_TABLE_STATE_KEY = "MANAGE-USERS-TABLE";
export const USER_TABLE_COLUMNS: Column[] = [
  {
    textKey: "access-profile-id",
    shortTextKey: "access-profile-id",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "name",
    shortTextKey: "name",
    type: "sspa-link",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "user-name",
    shortTextKey: "user-name",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "e-mail",
    shortTextKey: "e-mail",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "telephone-number",
    shortTextKey: "telephone-number",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "roles",
    shortTextKey: "roles",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "organisations",
    shortTextKey: "organisations",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "units",
    shortTextKey: "units",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "cost-locations",
    shortTextKey: "cost-locations",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "product-categories",
    shortTextKey: "product-categories",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "agreements",
    shortTextKey: "agreements",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "vpns",
    shortTextKey: "vpns",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "login-method",
    shortTextKey: "login-method",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "last-login",
    shortTextKey: "last-login",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: true,
  },
  {
    textKey: "invited-by",
    shortTextKey: "invited-by",
    type: "truncatable-text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
  {
    textKey: "invited",
    shortTextKey: "invited",
    type: "text",
    sortable: true,
    filterable: true,
    defaultVisible: false,
  },
];

export const EXPORT_TYPE_CSV = "csv";
export const EXPORT_TYPE_XLSX = "xlsx";
