import React, { useEffect, useState } from "react";
import { B2xOnboarding, B2xOnboardingStep } from "@telia/b2x-onboarding/react-cjs";
import firstManageUser from "./assets/1_hantera_anvandare_1.gif";
import secondManageUser from "./assets/1_hantera_anvandare_2.gif";
import { useMessage } from "@messageformat/react";

interface StepImages {
  src: string | undefined;
}

const steps: StepImages[] = [
  {
    src: firstManageUser,
  },
  {
    src: secondManageUser,
  },
];
export function ManageUsersOnboarding({ showOnboarding }: { showOnboarding: boolean | undefined }) {
  const [show, setShow] = useState<boolean | undefined>(showOnboarding);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const messages = useMessage("onboarding.manageUsers");

  useEffect(() => {
    if (showOnboarding !== undefined) {
      setShow(true);
    }
  }, [showOnboarding]);

  return (
    <B2xOnboarding
      isOpen={show}
      onCloseModal={() => setShow(false)}
      onNextStepClick={() => setCurrentStep((prev) => prev + 1)}
      onPreviousStepClick={() => setCurrentStep((prev) => prev - 1)}
    >
      {steps.map((step: StepImages, index) => (
        <B2xOnboardingStep
          key={`users-step-${index}`}
          {...step}
          heading={messages.heading()}
          description={messages.description()}
          active={currentStep === index}
        />
      ))}
    </B2xOnboarding>
  );
}
