import React, { useEffect, useState } from "react";
import { B2xOnboarding, B2xOnboardingStep } from "@telia/b2x-onboarding/react-cjs";
import { useMessage } from "@messageformat/react";
import firstManageInvitations from "./assets/2_skickade inbjudningar.gif";

interface StepImages {
  src: string | undefined;
}

const steps: StepImages[] = [{ src: firstManageInvitations }];

export function ManageInvitationsOnboarding({
  showOnboarding,
}: {
  showOnboarding: boolean | undefined;
}) {
  const [show, setShow] = useState<boolean | undefined>(showOnboarding);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const messages = useMessage("onboarding.manageUsers");

  useEffect(() => {
    if (showOnboarding !== undefined) {
      setShow(true);
    }
  }, [showOnboarding]);

  return (
    <B2xOnboarding isOpen={show} onCloseModal={() => setShow(false)}>
      {steps.map((step: StepImages, index) => (
        <B2xOnboardingStep
          key={`invitations-step-${index}`}
          {...step}
          heading={messages.heading()}
          description={messages.description()}
          active={currentStep === index}
        />
      ))}
    </B2xOnboarding>
  );
}
